import React from 'react';
import { Formik, Form, Field } from 'formik';
import Button from 'react-bootstrap/Button';
import { getUser } from '../modules/apiUser';

import '../App.css';

// https://bezkoder.com/react-jwt-auth/

function Login() {
  return (
    <>
      <div align='center' className='login'>
        <Formik
          initialValues={{ email: '', password: '' }}
          /*validate={(values) => {
          const errors = {};

          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          return errors;
        }}*/
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              getUser(values.email, values.password)
                .then((resolve) => {
                  console.log(resolve);
                  window.location.reload();
                })
                .catch((err) => {
                  console.log(err);
                });
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <label>Email :</label>
              <br />
              <Field name='email' />
              <br />
              <br />
              <label>Password :</label>
              <br />
              <Field name='password' type='password' />
              <br />
              <br />
              <Button type='submit' disabled={isSubmitting}>
                Se connecter
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Login;
