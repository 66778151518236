import React from 'react';
import Select from 'react-select';

/*interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
}*/

export const CustomSelect = ({ field, form, options, isMulti = false }) => {
  const onChange = (option) => {
    if (option) {
      form.setFieldValue(
        field.name,
        isMulti ? option.map((item) => item.value) : option.value
      );
    } else form.setFieldValue(field.name, '');
  };

  const getValue = () => {
    if (options && field && field.value) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
    />
  );
};
