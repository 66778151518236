import { Radio, RadioGroup } from '@palmerhq/radio-group';
import '@palmerhq/radio-group/styles.css'; // use the default styles
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import '../App.css';
import { CustomSelect } from '../components/customselect';
import Photos from '../components/photos';
import {
  getGolfById, getNetworkNames, updateGolfById
} from '../modules/apiGolfs';
import { logout } from '../modules/apiUser';

// https://awesomeopensource.com/project/palmerhq/radio-group

const excludeFields = [
  'id',
  'date_create',
  'date_update',
  'latitude',
  'longitude',
  'price_min_hs',
  'price_max_hs',
];

function Golf() {
  let { id } = useParams();

  const [golf, setGolf] = useState(null);
  const [networkNames, setNetworkNames] = useState(null);
  const [reviewStatus, setReviewStatus] = useState('0');
  const [updateStatus, setUpdateStatus] = useState(0); // 1 error, 2 ok

  const updateGolf = async (golfForm) => {
    if (golfForm) {
      golfForm.review_status = reviewStatus;
      // la gestion des photos est indépendante dans son propre composant, si on ne supprime pas à l'update ici, les modifs pourraient être écrasées
      delete golfForm.photos;
      const res = await updateGolfById(id, golfForm);
      if (res) setUpdateStatus(1);
      else setUpdateStatus(2);
    }
  };

  useEffect(() => {
    async function fetchGolf() {

      const result = await getGolfById(id);
      if (result && result.error && result.error === 401) {
        logout();
        window.location.reload();
      }

      let resultNetworks = await getNetworkNames();
      if (resultNetworks) {
        resultNetworks = resultNetworks.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      }

      setGolf(result);
      setNetworkNames(resultNetworks);
      setReviewStatus(result.review_status.toString());
    }
    try {
      fetchGolf();
    }
    catch (e) {
      console.error(e)
      logout();
      window.location.reload();
    }
  }, [id]);

  return (
    <div className='editgolf'>
      <h1>{golf ? golf.title : 'Loading ...'}</h1>
      {golf ? (
        <div className='maincontent'>
          <div className='form'>
            <Formik
              initialValues={{ ...golf }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  updateGolf(values);
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  {Object.keys(golf).map((item, value) => {
                    if (
                      item === 'review_status' ||
                      item === 'photos' ||
                      item === 'network' ||
                      item === 'networks' ||
                      item === 'guide' ||
                      item === 'comments'
                    )
                      return null;
                    return (
                      <div
                        key={item}
                        className={
                          excludeFields.includes(item) ? 'displaynone' : null
                        }
                      >
                        {item === 'description' ? (
                          <>
                            <label>{item} :</label>
                            <Field component='textarea' rows='2' name={item} />
                            <div style={{ display: 'none' }}><br />

                              <label>photos :</label>
                              <Field
                                component='textarea'
                                rows='2'
                                name='photos'
                                className='textarea2'
                              />
                            </div>
                            <br />
                            <label>comments :</label>
                            <Field
                              component='textarea'
                              rows='2'
                              name='comments'
                              className='textarea2'
                            />
                            <br />
                            <label>networks :</label>
                            <Field
                              name='networks'
                              options={networkNames}
                              component={CustomSelect}
                              placeholder='Select a language...'
                              isMulti={true}
                            />
                            <br />
                            <label>guide :</label>
                            <Field name='guide' />
                          </>
                        ) : (
                          <>
                            <label>{item} :</label>
                            <Field name={item} />
                          </>
                        )}
                        <br />
                      </div>
                    );
                  })}
                  <br />
                  <div className='submitgolf'>
                    <div className='review_status'>
                      <RadioGroup
                        name='review_status'
                        labelledBy='review_status'
                        value={reviewStatus}
                        onChange={(value) => setReviewStatus(value)}
                      >
                        <Radio value='0'>
                          <span style={{ color: 'red', fontWeight: 'bold' }}>
                            A faire
                          </span>
                        </Radio>
                        <Radio
                          value='1'
                          style={{ color: '#c77700', fontWeight: 'bold' }}
                        >
                          Incomplet
                        </Radio>

                        <Radio
                          value='2'
                          style={{ color: 'green', fontWeight: 'bold' }}
                        >
                          Vert
                        </Radio>
                      </RadioGroup>
                    </div>
                    <Button
                      href={`${window.basename}/golf/${parseInt(id, 10) - 1}`}
                      variant='info'
                    >
                      &lt; Précédent
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                      Enregistrer
                    </Button>
                    <Button
                      href={`${window.basename}/golf/${parseInt(id, 10) + 1}`}
                      variant='info'
                    >
                      Suivant &gt;
                    </Button>
                    {updateStatus ? (
                      <>
                        <div
                          style={{
                            marginLeft: '45%',
                            fontWeight: 'bold',
                            color: updateStatus === 1 ? 'green' : 'red',
                            position: 'absolute',
                          }}
                        >
                          Update ok
                        </div>
                      </>
                    ) : null}
                  </div>
                </Form>
              )
              }
            </Formik>
          </div>
          <div className='right'>
            <Photos id={id} />
            {golf.url ? (
              <>
                URL :{' '}
                <a href={golf.url} target='_blank' rel='noopener noreferrer'>
                  {golf.url}
                </a>
              </>
            ) : null}
            {golf.facebook ? (
              <>
                <br />
                Facebook :{' '}
                <a
                  href={golf.facebook}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {golf.facebook}
                </a>
              </>
            ) : null}
            {golf.youtube ? (
              <>
                <br />
                Youtube Page :{' '}
                <a
                  href={golf.youtube}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {golf.youtube}
                </a>
              </>
            ) : null}
            {golf.instagram ? (
              <>
                <br />
                Instagram :{' '}
                <a
                  href={golf.instagram}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {golf.instagram}
                </a>
              </>
            ) : null}
            {golf.twitter ? (
              <>
                <br />
                Twitter :{' '}
                <a
                  href={golf.twitter}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {golf.twitter}
                </a>
              </>
            ) : null}

            {golf.video ? (
              <>
                <br />
                <br />
                <a href={golf.video} target='_blank' rel='noopener noreferrer'>
                  {golf.video}
                </a>
                <br />
                <iframe
                  title='youtube'
                  src={golf.video}
                  width='400'
                  height='200'
                />
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Golf;
