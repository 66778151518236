import React, { useCallback, useState } from 'react';
import { Lightbox } from "react-modal-image";
import { removeOnePhoto } from '../modules/apiGolfs';

const base_url = window.images_root

export default function Imagesshow({ id, photos, onDeleteImage }) {
    const [currentImage, setCurrentImage] = useState()
    const [isOpenBox, setIsOpenBox] = useState(false)

    const onCloseBox = useCallback(() => {
        setIsOpenBox(false)
    }, [])

    const onClickImage = useCallback((e) => {
        setCurrentImage(e.nativeEvent.target.getAttribute("data-url"))
        setIsOpenBox(true)
    }, [])

    const onClickDelete = useCallback(async (e) => {
        e.stopPropagation();
        if (window.confirm('Etes-vous sûr de vouloir supprimer cette image ?')) {
            removeOnePhoto(id, e.target.getAttribute("data-id")).then(() => {
                onDeleteImage()
            })
        }
    }, [id])

    const listImages = photos.map(image => {
        return <div key={image}
            data-url={base_url + image}
            style={{ backgroundImage: 'url(' + base_url + image + ')' }}
            className='container-image' onClick={onClickImage}>
            <div data-id={image} className='delete' onClick={onClickDelete} alt='delete image'>X</div>
        </div>
    })

    return (
        <div className='images-show'>
            {listImages}
            {isOpenBox &&
                <Lightbox
                    large={currentImage}
                    onClose={onCloseBox}
                />}
        </div>
    )
}
