import React, { useCallback, useEffect, useState } from 'react';
import { getGolfById } from '../modules/apiGolfs';
import Imagesshow from './imagesshow';
import Uploader from './uploader';

export default function Photos({ id }) {
    const [photosList, setPhotosList] = useState([])

    const fetchGolf = useCallback(async () => {
        const result = await getGolfById(id);
        setPhotosList(result['photos'].split('\n').filter(v => v.trim() !== ''))
    }, [id])

    useEffect(() => {
        fetchGolf();
    }, [id, fetchGolf]);

    return (
        <div className='photos'>
            <span style={{ fontWeight: 'bold' }}>Gestion des photos :</span>
            <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                <Imagesshow id={id} photos={photosList} onDeleteImage={fetchGolf} />
            </div>
            <div style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
                <Uploader id={id} onUploaded={fetchGolf} />
            </div>
        </div>
    )
}
