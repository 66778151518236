import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import './App.css';
import Router from './components/router';
import NavBar from './components/navbar';
import { isAuthenticate } from './modules/apiUser';

function App() {
  /*const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (!isAuth && isAuthenticate()) setIsAuth(true);
    if (isAuth && !isAuthenticate()) setIsAuth(false);
  });*/

  const isAuth = isAuthenticate();

  return (
    <>
      <div className='header'></div>
      <div className='content'>
        <NavBar isAuth={isAuth} />
        <Router isAuth={isAuth} />
      </div>
    </>
  );
}

export default App;
