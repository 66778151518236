import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { updateGolfById } from '../modules/apiGolfs';

export default class CheckBox extends React.Component {
  constructor(props) {
    super();

    const { isChecked } = props;
    this.state = { isChecked, isUpdating: false };
  }

  handleChecked = async () => {
    const { isChecked } = this.state;
    const { id } = this.props;

    const newCheck = !isChecked;
    this.setState({ isUpdating: true });

    const res = await updateGolfById(id, { guide: newCheck ? 1 : 0 });
    this.setState({
      isUpdating: false,
      isChecked: res ? !isChecked : isChecked,
    });
  };

  render() {
    const { isChecked, isUpdating } = this.state;

    if (isUpdating) return <Spinner animation='border' size='sm' />;
    return (
      <input
        type='checkbox'
        onChange={this.handleChecked}
        checked={isChecked}
      />
    );
  }
}
