import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadGolfImage } from '../modules/apiGolfs';

/*
TODO:
- supprimer le fichier de la dropzone quand il a été uploadé
- gérer l'affichage des photos, au chargement, et après l'upload (on refait un fetch au global et on update le state du composant photos)
- gérer la suppression des fichiers
*/

const STATUS_UPLOAD = {
    Nothing: 0,
    Pending: 1,
    Success: 2,
    Failed: 3
}

const MAX_SIZE = 5242880 // 5 Mo maximum

export default function Uploader({ id, onUploaded }) {
    const [files, setFiles] = React.useState([]);
    const [status, setStatus] = React.useState(STATUS_UPLOAD.Nothing);

    const onDrop = useCallback(acceptedFiles => {
        // console.log('<b>Accepted: </b>', acceptedFiles[0])
        setFiles(acceptedFiles);
    }, [])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        acceptedFiles,
        // fileRejections,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: { 'image/*': ['.png', '.jpg', '.jpeg'] }, maxSize: MAX_SIZE, onDrop, multiple: false });


    const resetFiles = useCallback(() => {
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        setFiles([])
    }, [acceptedFiles])

    const onClickUpload = useCallback(async () => {
        let result = false
        if (files[0]) {
            setStatus(STATUS_UPLOAD.Pending)
            result = await uploadGolfImage(id, files[0])
            if (result) {
                setStatus(STATUS_UPLOAD.Success)
                onUploaded()
            }
            else setStatus(STATUS_UPLOAD.Failed)
            resetFiles()
        }
        else setStatus(STATUS_UPLOAD.Failed)

    }, [id, files, resetFiles])

    const acceptedFileItems = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <div className='uploader'>
            <div className='upload-zone' {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <em>(Only *.jpg, *.jpeg and *.png images will be accepted, and 5 Mo maximum)</em>
                <ul style={itemsFiles}>
                    {acceptedFiles && acceptedFileItems}
                </ul>
            </div>
            {status !== STATUS_UPLOAD.Pending ?
                <button onClick={onClickUpload}>Uploader</button>
                :
                <span>Uploading ...</span>}
            {status === STATUS_UPLOAD.Success && <span style={{ color: 'green' }}>Upload ok !</span>}
            {status === STATUS_UPLOAD.Failed && <span style={{ color: 'red' }}>Echec upload</span>}
        </div>
    )
}

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#666',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const itemsFiles = {
    listStyle: 'none',
    marginTop: 10,
    fontWeight: 'bold'
}
