import axios from 'axios';

export function getUser(email, password) {
  const url = `${window.api_root}/auth`;
  const params = {
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
    },
  };

  return axios
    .post(url, { email, password }, params)
    .then((response) => response.data)
    .then((responseJson) => {
      if (!responseJson) throw Error('Empty response');

      if (responseJson.token) {
        localStorage.setItem('user', JSON.stringify(responseJson));
      }

      return responseJson;
    })
    .catch((e) => {
      throw Error(e);
    });
}

export function logout() {
  localStorage.removeItem('user');
}

export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function isAuthenticate() {
  const user = JSON.parse(localStorage.getItem('user'));
  return user && user.token ? true : false;
}
