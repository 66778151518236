// window.api_root = 'http://localhost:3001';
// window.images_root = 'http://localhost:3001/images/';
window.api_root = 'https://sd1.mldatago.com';
window.images_root = 'https://sd1.mldatago.com/images/';
window.basename = '';
//window.api_root = 'http://ns38281.ovh.net:3001';

//if (process.env.NODE_ENV === 'production') {
// window.basename = '/golfapi';
// window.api_root = 'http://ns38281.ovh.net:3001';
//}
