import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';
import { logout } from '../modules/apiUser';

export default function NavBar({ isAuth }) {
  let location = useLocation();

  return (
    <Breadcrumb>
      <Breadcrumb.Item href={window.basename + '/'}>Accueil</Breadcrumb.Item>
      {location.pathname.indexOf('/login') !== -1 ? (
        <Breadcrumb.Item
          href={window.basename + '/login'}
          active={location.pathname.indexOf('/login') !== -1}
        >
          Login
        </Breadcrumb.Item>
      ) : null}
      {location.pathname.indexOf('/list') !== -1 ||
        location.pathname.indexOf('/golf') !== -1 ? (
        <Breadcrumb.Item
          href={window.basename + '/list'}
          active={location.pathname.indexOf('/list') !== -1}
        >
          Liste
        </Breadcrumb.Item>
      ) : null}
      {isAuth ? (
        <div style={style.logout}>
          <a
            href="#"
            onClick={() => {
              logout();
              window.location.reload();
            }}
            alt="logout"
          >
            Logout
          </a>
        </div>
      ) : null}
    </Breadcrumb>
  );
}

const style = {
  logout: {
    position: 'absolute',
    right: '40px',
  },
};
