import axios from 'axios';
import authHeader from './authHeader';

export function getGolfs() {
  const url = `${window.api_root}/golfs`;

  const params = {
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
      ...authHeader(),
    },
  };

  return axios
    .get(url, params)
    .then((response) => response.data)
    .then((responseJson) => {
      if (!responseJson) throw Error('Empty response');

      return responseJson;
    })
    .catch((e) => {
      if (e.response) return { error: e.response.status };
      throw Error(e);
    });
}

export function getGolfById(id) {
  const url = `${window.api_root}/golfs/${id}`;
  const params = {
    timeout: 4000,
    headers: {
      'Cache-Control': 'no-cache',
      ...authHeader(),
    },
  };

  return axios
    .get(url, params)
    .then((response) => response.data)
    .then((responseJson) => {
      if (!responseJson) throw Error(`Empty response for id:${id}`);

      return responseJson;
    })
    .catch((e) => {
      if (e.response) return { error: e.response.status };
      throw Error(e);
    });
}

export function updateGolfById(id, golf) {
  const url = `${window.api_root}/golfs/${id}`;
  const params = {
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
      ...authHeader(),
    },
  };

  return axios
    .put(url, golf, params)
    .then((response) => response.data)
    .then((responseJson) => {
      if (responseJson && responseJson.id) return true;
      else throw Error('Fail');
    })
    .catch((e) => {
      //throw Error(e);
      console.log(`UPDATE ${id} ERROR`, e);
      return false;
    });
}

export function removeOnePhoto(id, photoId) {
  const url = `${window.api_root}/removephoto/${id}/${photoId}`;
  const params = {
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
      ...authHeader(),
    },
  };

  return axios
    .delete(url, params)
    .then((response) => response.data)
    .then((responseJson) => {
      if (responseJson) return true;
      else throw Error('Fail');
    })
    .catch((e) => {
      console.log(`REMOVE PHOTO ERROR ${id} ERROR`, e);
      return false;
    });
}

export function getNetworkNames() {
  const url = `${window.api_root}/networknames`;

  const params = {
    timeout: 10000,
    headers: {
      'Cache-Control': 'no-cache',
      ...authHeader(),
    },
  };

  return axios
    .get(url, params)
    .then((response) => response.data)
    .then((responseJson) => {
      if (!responseJson) throw Error('Empty response');
      return responseJson;
    })
    .catch((e) => {
      if (e.response) return { error: e.response.status };
      throw Error(e);
    });
}

export async function uploadGolfImage(id, image) {
  const url = `${window.api_root}/upload`;

  const formData = new FormData();

  formData.append("image", image)
  formData.append("id", id)

  const headers = {
    'Accept': 'application/json, */*',
    ...authHeader(),
  }
  const init = {
    headers,
    mode: 'cors', // no-cors, cors, same-origin
    method: 'POST',
    body: formData,
  };


  return fetch(url, init)
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson && responseJson.message?.id) return true;
      else return false
    })
    .catch((e) => {
      console.log(`UPDATE ${id} ERROR`, e);
      return false;
    });
}
