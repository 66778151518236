import React from 'react';
import {
  Redirect, Route, Switch, useLocation
} from 'react-router-dom';
import Golf from '../views/golf';
import List from '../views/list';
import Login from '../views/login';

export default function Router({ isAuth }) {
  let location = useLocation();

  if (isAuth && location.pathname.indexOf('/login') !== -1) {
    return <Redirect to='/list' />;
  } else if (!isAuth && location.pathname.indexOf('/login') === -1) {
    return <Redirect to='/login' />;
  }

  return (
    <div>
      <Switch>
        <Route path='/list'>
          <List />
        </Route>
        <Route path='/golf/:id'>
          <Golf />
        </Route>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/'>
          <List />
        </Route>
      </Switch>
    </div>
  );
}
