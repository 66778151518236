import React, { useEffect, useState } from 'react';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import { getGolfs } from '../modules/apiGolfs';
import { logout } from '../modules/apiUser';
import CheckBox from '../components/checkbox';
import '../App.css';

const columns = [
  {
    dataField: 'id',
    text: 'Id',
    sort: true,
    filter: textFilter(),
    headerStyle: { width: '100px' },
  },
  {
    dataField: 'guide',
    mode: 'checkbox',
    text: 'Guide',
    sort: true,
    formatter: guideFormatter,
    headerStyle: { width: '100px' },
  },
  {
    dataField: 'review_status',
    text: 'Status',
    sort: true,
    filter: textFilter(),
    formatter: reviewStatusFormatter,
    headerStyle: { width: '100px' },
  },
  {
    dataField: 'title',
    text: 'Titre',
    sort: true,
    filter: textFilter(),
    formatter: titleFormatter,
  },
  {
    dataField: 'region',
    text: 'Région',
    sort: true,
    filter: textFilter(),
  },
  {
    dataField: 'city',
    text: 'Ville',
    sort: true,
    filter: textFilter(),
  },
];

function titleFormatter(cell, row, rowIndex, formatExtraData) {
  const { id } = row;
  return (
    <Link to={`/golf/${id}`} target='_blank'>
      {cell}
    </Link>
  );
}

function guideFormatter(cell, row, rowIndex, formatExtraData) {
  const { id } = row;
  return <CheckBox id={id} isChecked={cell ? true : false} />;
}

function reviewStatusFormatter(cell, row, rowIndex, formatExtraData) {
  if (cell === 1)
    return (
      <span style={{ color: 'orange' }}>
        <strong>{cell}</strong>
      </span>
    );
  else if (cell === 2)
    return (
      <span style={{ color: 'green' }}>
        <strong>{cell}</strong>
      </span>
    );
  return <span style={{ color: 'gray' }}>{cell}</span>;
}

function List() {
  const [listGolfs, setListGolfs] = useState(null);

  useEffect(() => {
    async function fetchListGolfs() {
      const result = await getGolfs();
      if (result && result.error && result.error === 401) {
        logout();
        window.location.reload();
      }
      setListGolfs(result);
    }
    fetchListGolfs();
  }, []);

  return (
    <div className='List'>
      <div>
        {listGolfs ? (
          <BootstrapTable
            bootstrap4
            keyField='id'
            data={listGolfs}
            columns={columns}
            filter={filterFactory()}
          />
        ) : null}
      </div>
    </div>
  );
}

export default List;
